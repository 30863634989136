import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import { FeatureGrid } from "../../components/businessLanding/featureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";
import DemoInfo from "./demo-info";

const { Content } = Layout;

const WorkDashboardLanding = () => {
  const [videoPaused, setVideoPaused] = useState(true);
  const vidRef = useRef(null);
  //  const vidRef2 = useRef(null);

  const handlePlayVideo = () => {
    if (videoPaused === true) {
      vidRef.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  // const handlePlayVideo2 = () => {
  //   if (videoPaused === true) {
  //     vidRef2.current.play();
  //     setVideoPaused(false);
  //   } else {
  //     setVideoPaused(true);
  //   }
  // };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="DayViewer Online Work Scheduler, Planner and Dashboard"
        />
        <title>
          Online Work Scheduler, Planner &amp; Task Management System
        </title>
      </Helmet>
      <Layout>
        <CommonHeader />
      </Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />

        <Content className="homepage-background">
          <Row>
            <div className="homepage-banner">
              <Col lg={12} xs={24}>
                <div className="homepage-jumbotron">
                  <div className="homepage-headlines">
                    <h1>Effective Online Work Planner & Task Management App</h1>
                    <h2>
                      The Best Online Work Planner & Scheduler To Manage Tasks
                      In Your Team.
                    </h2>

                    <div style={{ paddingTop: 20 }}>
                      <p>
                        When you have many jobs and tasks to keep track of, it
                        makes sense for your business to plan and schedule work
                        around one shared calendar planner &amp; task management
                        system.
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="homepage-signup-btn">
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://app.dayviewer.com/auth/signup",
                          "_self"
                        )
                      }
                    >
                      Create Your Team Planner
                    </a>
                  </Button>
                </div>
                <div style={{ textAlign: "center", padding: 20 }}>
                  <h5>
                    14 Days Free Trial -
                    <Popover
                      content={
                        <div style={{ width: "500px" }}>
                          <TeamPlanListForPopUp />
                        </div>
                      }
                      trigger="click"
                      title="Plan Information"
                    >
                      <a href="#"> Pricing </a>
                    </Popover>
                    <span> | </span>
                    {/* <Popover
                        overlayStyle={{ width: 500 }}
                        content={<DemoInfo />}
                        trigger="click"
                      > */}
                    <a href="https://dayv.me/team-board-2" target="_blank">
                      Demo Videos
                    </a>
                    {/* </Popover>  */}
                  </h5>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                {/* <LandingTeamSlider /> */}
                <div style={{ marginTop: 10 }}>
                  <video
                    ref={vidRef}
                    onClick={handlePlayVideo}
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                    // muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="Online Work Scheduler"
                    src="https://res.cloudinary.com/dayviewer1/video/upload/v1669137012/DV_fast_planner_scheduling-bg-music_q7v3xb.mp4"
                  />
                </div>
              </Col>
            </div>
          </Row>

          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Col lg={12} xs={24}>
                <div>
                  <picture>
                    <Image
                      src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-schedule.png"
                      //src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                      className="homepage-image"
                      width="100%"
                      height="100%"
                      alt="Online Team Task Planner"
                      title="Online Team Task Planner"
                    />
                  </picture>
                </div>
              </Col>
              <Col lg={12} xs={0}>
                <div className="homepage-headlines">
                  <h1>DayViewer Task &amp; Work Scheduler</h1>
                  <h2>A More Efficient Way To Work Together</h2>
                </div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <h4>Schedule work more efficiently. </h4>
                    DayViewer acts as your team dashboard, planner and work
                    scheduler system with added ability to add notes, documents,
                    checklists, comments, content and files/images to entries
                  </li>
                  <li>
                    <h4>Easily see the status of jobs and tasks at hand.</h4>{" "}
                    With the numerous views, task management becomes easier.
                  </li>
                  <li>
                    <h4>Use on any Phone, Tablet or Desktop</h4> DayViewer
                    converts to a fully functional app on any phone operating
                    system. simply by adding to your homescreen or download the
                    app. So whatever you see on your desktop is available on
                    your device. You can add and update tasks on the go.
                  </li>
                  <p>
                    Sign up today and create your team room. Take the 14 day
                    trial with members simply by adding them from inside your
                    team room. (Note: you can also create multiple team rooms
                    for example one for each department, service or location).
                  </p>
                </ul>
                <div style={{ textAlign: "center" }}>
                  <Button className="homepage-signup-btn">
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://app.dayviewer.com/auth/signup",
                          "_self"
                        )
                      }
                    >
                      Create Your Team Dashboard - Free Trial
                    </a>
                  </Button>
                </div>
                {/* <div style={{ textAlign: "center", padding: 20 }}>
                    <h5>
                      14 Days Free Trial -
                      <Popover
                        content={
                          <div style={{ width: "500px" }}>
                            <TeamPlanListForPopUp />
                          </div>
                        }
                        trigger="click"
                        title="Plan Information"
                      >
                        <a href="#"> Pricing </a>
                      </Popover>
                      <span> | </span>
                      <Popover
                        overlayStyle={{ width: 500 }}
                        content={<DemoInfo />}
                        trigger="click"
                      >
                        <a href="#"> Request Demo</a>
                      </Popover>
                    </h5>
                  </div> */}
              </Col>
            </div>
          </Row>
          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Row>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <h2>
                      Labels View Helps You View Everything Related at a Click!
                    </h2>
                    <div>
                      <p>
                        labelling similar entries that may be connected by
                        category, project or other attribute, will enable those
                        entries to show in the label management area.
                      </p>
                      <p>
                        Labelling is also a visual signifier, keeping related
                        information together by color.
                      </p>
                      <p>
                        For Example: A task on a Project with label ProjectX,
                        can be linked with a note with a file or checklist, by
                        labelling that note ProjectX also.
                      </p>
                      <p>
                        Labels provide a superior way to keep information
                        organized and accessible.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Work Scheduler
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5>
                      </div> */}
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                        //src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Team Task Planner"
                        title="Online Team Task Planner"
                      />
                    </picture>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        //src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Work Scheduler"
                        title="Online Work Scheduler"
                      />
                    </picture>
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                    }}
                  >
                    <h2>Team Task Progress Viewing and Filtering</h2>
                    <div>
                      <p>
                        Task cards are a handy way to see the your tasks and
                        jobs laid out and their current progress. Filter and
                        search the jobs you need to focus on easily.
                      </p>
                      <p>
                        Quick filter links at the top of the cards view help you
                        to easily view which jobs haven't started yet, have
                        started, but are in progress, are overdue, and all
                        completed tasks.
                      </p>
                      <p>
                        The cards also visually display which tasks have become
                        overdue, so you can see them at a glance, and either
                        push those tasks out or reassign as required.
                      </p>
                      <p>
                        Provides an easy to use platform for your employees or
                        colleagues to update their tasks status leaving
                        management free for working on strategy and reaching
                        their own goals.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Get Work Organized Efficiently
                        </a>
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "100px",
                    }}
                  >
                    <h2>Work Schedule And Task Management</h2>
                    <div>
                      <p>
                        Get a clear overview of tasks, so you can easily see who
                        is doing what, and when it needs to be done by.
                      </p>
                      <p>
                        It's a great way to see workload distribution, who is
                        really busy at the moment, their progress, and who has
                        some some spare capacity.
                      </p>
                      <p>
                        There are many views where your team's work schedule can
                        be viewed, it's perfect for field agents, engineers who
                        need to go on-site, appointments, bookings and more.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Open Your Team Room
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5>
                      </div> */}
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        //src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-dash-tasks.png"
                        src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Team Task Planner"
                        title="Online Team Task Planner"
                      />
                    </picture>
                  </div>
                </Col>
                {/* <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <img
                      className="centered-and-cropped-cover position-center"
                      width="1000"
                      height="600"
                      src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-dash-tasks.png"
                      alt="team tasks dashboard"
                      title="team tasks dashboard"
                    />
                  </div>
                </Col> */}
              </Row>
            </div>
          </Row>
          <Row>
            <FeatureGrid />
          </Row>
        </Content>
      </Layout>
      <Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export default WorkDashboardLanding;
